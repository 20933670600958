import React, { FC, useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, HashRouter, Route, Routes, useRoutes } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import RouterNoLogged from './routes/RouterNoLogged';
import './css/styles.css'
import { AppState } from './store/Store';
import DeviceInfo from './Tools/DeviceInfo';
import Configuration from './Configuration';
import { useDispatch } from 'react-redux';
import { setActiveTransTrack } from './store/apps/transactionTrack/TransactionTrackSlice';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function RouteWatcherTransactionTrack() {
  const location = useLocation();
    const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/transactionTrack') {
      dispatch(setActiveTransTrack(true))
    } else {
      dispatch(setActiveTransTrack(false))
    }
  }, [location, setActiveTransTrack]);

  return null;
}

const App: FC = () => {
  const routing = useRoutes(Router);
  const routingNoLogged = useRoutes(RouterNoLogged);
  const theme = ThemeSettings();
  const customizer = useSelector((state: AppState) => state.customizer);
  const userData = useSelector((state: AppState) => state.userData);
  console.log("userData", userData)
  useEffect(() => {
    if (process.env.REACT_APP_ENV == "production") {
      console.log('Apagando Console');
      console.log = () => { };
      console.info = () => { };
    }
    getDeviceInfo()
    document.title = Configuration.name || "";

    const link = document.querySelector('link[rel="icon"]')
    if(link){
      link.setAttribute('href', Configuration.favicon || "");
    }
  }, []);

  const getDeviceInfo = async () => {
    let deviceInfo = DeviceInfo.getDeviceInfo();
    console.log('DeviceInfo: ', deviceInfo)
    let deviceInfoSaved = localStorage.getItem('deviceInfo');
    if(deviceInfoSaved){
      deviceInfoSaved = JSON.parse(deviceInfoSaved)
    }
    if (!deviceInfoSaved) {
      let response = await DeviceInfo.saveDeviceInfoOnServer(deviceInfo);
      console.log('Response: ', response)
      localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
    } else {
      console.log('Ya existe información guardada.')
      let dataToSend = Object.assign({}, deviceInfoSaved, deviceInfo);
      let response = await DeviceInfo.saveDeviceInfoOnServer(dataToSend);
      console.log('Response: ', response)
      localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
    }
  }

  if (userData.isLoggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <ScrollToTop>{routing}</ScrollToTop>
        </RTL>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <RouteWatcherTransactionTrack />
          <ScrollToTop>{routingNoLogged}</ScrollToTop>
        </RTL>
      </ThemeProvider>
    )
  }
};

export default App;
