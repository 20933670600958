
//import Dolex from './assets/images/Logo/Dolex'
import Dolex from './assets/images/Logo/Dolex_.png'
import Barri from './assets/images/Logo/Barri'

const logos: any = { Barri: Barri, Dolex: Dolex }

const Configuration = {
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    enviroment: process.env.REACT_APP_ENVIROMENT,
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR,
    primaryTintColor: process.env.REACT_APP_PRIMARY_TINT_COLOR,
    name: process.env.REACT_APP_NAME,
    brandName: process.env.REACT_APP_BRAND_NAME,
    shortName: process.env.REACT_APP_SHORT_NAME,
    appVersion: process.env.REACT_APP_VERSION,
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#AFD345',
    googleKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    logo: logos[process.env.REACT_APP_BRAND_NAME || 'Barri'],
    phone: process.env.REACT_APP_PHONE,
    operator: process.env.REACT_APP_OPERATOR,
    userAgreement: process.env.REACT_APP_USER_AGREEMENT,
    favicon: process.env.REACT_APP_FAVICON,
    captchaKey: process.env.REACT_APP_CAPTCHA_KEY || '',
    cdUrl: process.env.REACT_APP_CD_URL,
    appsfylerUrl: process.env.REACT_APP_APPSFLYER_URL,
    termsConditions: process.env.REACT_APP_TERMSCOND_URL,
    privacyPolicy: process.env.REACT_APP_PRIVPOLICY_URL,
    phoneNumTracking: process.env.REACT_APP_PHONENUM_TRACKING,
    mainPage: process.env.REACT_APP_MAIN_PAGE
}

export default Configuration;