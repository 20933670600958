import Request from "./Request"
import moment from "moment"
import Config from "src/Configuration"
import SweetAlert from 'src/Tools/SweetAlert';

let AppConfiguration = {
  getI18n: () => {
    //storage.removeItem('i18n');
    return new Promise(async (resolve, reject) => {
      //Si el ambiente es de produccion entonces vamos a cachear las traducciones por 1 dia.
      if (process.env.ENV == 'prod') {
        let now = moment();
        let i18n : any = window.localStorage.getItem('i18n');
        if (i18n && i18n.lastUpdate) {
          let diff = now.diff(i18n.lastUpdate, 'days');
          if (!diff) {
            return resolve(i18n)
          }
        }
      }
      try {
        let response : any = await Request.post(`${Config.backendUrl}/getI18nWeb`, {}, {});
        response.lastUpdate = moment()
        window.localStorage.setItem('i18n', response);
        return resolve(response)
      } catch (error) {
        console.error('Error: ', error)
        return resolve({})
      }
    })
  },

  getLegalInfo: () => {
    //storage.removeItem('i18n');
    return new Promise(async (resolve, reject) => {
      //Si el ambiente es de produccion entonces vamos a cachear las traducciones por 1 dia.
      if (process.env.ENV == 'prod') {
        let now = moment();
        let i18n : any = window.localStorage.getItem('legalInfo');
        if (i18n && i18n.lastUpdate) {
          let diff = now.diff(i18n.lastUpdate, 'days');
          if (!diff) {
            return resolve(i18n)
          }
        }
      }
      try {
        let response = await Request.get(`${Config.backendUrl}/app/getLegalInfo`, {}, {});
        response.lastUpdate = moment()
        window.localStorage.setItem('legalInfo', response);
        return resolve(response)
      } catch (error) {
        console.error('Error: ', error)
        return resolve({})
      }
    })
  },

  getFeatures: (navigation : any) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response : any = await Request.get(`${Config.backendUrl}/app/getFeaturesWeb`, {}, {});
        response.lastUpdate = moment()
        return resolve(response)
      } catch (error : any) {
        Request.handleError(error.httpStatus, error.message);
        console.error('Error: ', error)
        return resolve([])
      }
    })
  },

  /**
   * @summary Get the product names
   * @returns 
   */
  getProductNames: async () => {
    try {
      let response = await Request.get(`${Config.backendUrl}/app/getProductNames`, {}, {});
      return response;
    } catch (error: any) {
      console.error('Error: ', error)
      SweetAlert.error(`${Config.name}`, error && error.message)
      Request.handleError(error && error.httpStatus || error && error.statusCode || 500, error && error.message)
      return error;
    }
  }

}

export default AppConfiguration