import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// authentication
//const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Login2 = Loadable(lazy(() => import('../views/authentication/auth2/Login2')));
//const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const Register2 = Loadable(lazy(() => import('../views/authentication/auth2/Register2')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const ForgotPassword2 = Loadable(
  lazy(() => import('../views/authentication/auth2/ForgotPassword2')),
);
const TwoSteps = Loadable(lazy(() => import('../views/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('../views/authentication/auth2/TwoSteps2')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));


const Login = Loadable(lazy(() => import('../views/pages/login/Login')));
const Register = Loadable(lazy(() => import('../views/pages/register/Register')));
const CompleteProfile = Loadable(lazy(() => import('../views/pages/register/CompleteProfile')));
const RecoveryPassword = Loadable(lazy(() => import('../views/pages/recoveryPassword/RecoveryPassword')));
const GuestQuote = Loadable(lazy(() => import('../views/pages/quote/GuestQuote')));
const TransactionTrack = Loadable(lazy(() => import('../views/pages/transactionTrack/TransactionTrack')));

const RouterNoLogged = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', element: <Navigate to="login" /> },
      { path: '/logout', element: <Navigate to="login" /> },
      { path: '/login', element: <Login /> },
      { path: '/login/:username', element: <Login /> },
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login2', element: <Login2 /> },
      { path: '/register', element: <Register /> },
      { path: '/completeProfile', element: <CompleteProfile /> },
      { path: '/recoveryPassword', element: <RecoveryPassword /> },
      { path: '/guestQuote', element: <GuestQuote isGuest /> },
      { path: '/transactionTrack', element: <TransactionTrack /> },

      { path: '/auth/register2', element: <Register2 /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      { path: '/auth/two-steps', element: <TwoSteps /> },
      { path: '/auth/two-steps2', element: <TwoSteps2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  }
];

export default RouterNoLogged;
