import axios from "axios";
import i18next from "i18next";
import Config from "src/Configuration";
const { v4: uuidv4 } = require('uuid');

interface optionsInterface {
  headers?: Object,
  message?: string
}

let Request = {
  post: (url: string, params: Object, options: optionsInterface) => {

    return new Promise(async (resolve, reject) => {
      const userData: any = JSON.parse(localStorage.getItem("userData") || "{}")
      try {
        //console.log('Token: ', window.localStorage.getItem('token'))
        let headersToSend = Object.assign(
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': i18next.language || userData && userData.lang || 'es',
            'Authorization': userData && userData.token || ''
          }, options.headers || {});

        let uniqueId = localStorage.getItem('uniqueId') || uuidv4();
        localStorage.setItem('uniqueId', uniqueId)

        let paramsToSend = Object.assign({
          appSource: `web`,
          appVersion: Config.appVersion,
          appName: `${Config.shortName}`,
          uniqueId: uniqueId
        }, params)

        let config: any = {};
        config.headers = headersToSend

        let res = await axios.post(url, paramsToSend, config)
        //console.log('Axios Response:', res.data);
        return resolve(res.data)
      } catch (error: any) {
        console.log("error", error);
        let errorMessage = options.message || (i18next.language == 'en' ? 'At the moment we are unable to process the information' : 'Por el momento no podemos procesar la información');
        //console.error('URL: ', url)
        if (error.code == "ERR_NETWORK") {
          console.error('Error', error.message);
          return reject({ message: error.message, httpStatus: 500 })
        } else if (error.response) {
          console.error(error.response.data);
          if (error.response.data && error.response.data && Array.isArray(error.response.data)) {
            return reject(error.response.data)
          } if (error.response.data && error.response.data && (typeof (error.response.data) === 'object' && (error.response.data) !== null && !Array.isArray(error.response.data))) {
            return reject(error.response.data)
          }
          return reject({ message: error.response.data && error.response.data.message || errorMessage, httpStatus: error.response.status })
        } else if (error.request) {
          console.error({ message: error.request, httpStatus: 500 });
          return reject({ message: i18next.language == 'en' ? 'No response was received from the server' : 'No se recibió una respuesta del servidor', httpStatus: 500 })
        } else {
          console.error('Error', error.message);
          return reject({ message: error.message, httpStatus: 500 })
        }
      }
    })
  },

  get: (url: string, params: Object, options: optionsInterface): Promise<any> => {

    return new Promise(async (resolve, reject) => {
      try {
        const userData: any = JSON.parse(localStorage.getItem("userData") || "{}")

        let headersToSend = Object.assign(
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': i18next.language || userData && userData.lang || 'es',
            'Authorization': userData && userData.token || ''
          }, options.headers || {});


        let uniqueId = localStorage.getItem('uniqueId') || uuidv4();
        localStorage.setItem('uniqueId', uniqueId)

        let paramsToSend = Object.assign({
          appSource: `web`,
          appVersion: Config.appVersion,
          appName: Config.shortName,
          uniqueId: uniqueId
        }, params)

        const config: any = {
          params: paramsToSend
        }

        config.headers = headersToSend
        console.log("config", config)
        let res = await axios.get(url, config)
        //let res = await axios.get(url, { params: paramsToSend, headers: headersToSend })
        //console.log('Axios Response:', res.data);
        return resolve(res.data)
      } catch (error: any) {
        console.log(error.response);
        let errorMessage = options.message || (i18next.language == 'en' ? 'At the moment we are unable to process the information' : 'Por el momento no podemos procesar la información');
        //console.error('URL: ', url)
        if (error.code == "ERR_NETWORK") {
          console.error('Error', error.message);
          return reject({ message: error.message, httpStatus: 500 })
        } else if (error.response) {
          console.error(error.response.data);
          return reject({ message: error.response.data && error.response.data.message || errorMessage, httpStatus: error.response.status })
        } else if (error.request) {
          console.error({ message: error.request, httpStatus: 500 });
          return reject({ message: i18next.language == 'en' ? 'No response was received from the server' : 'No se recibió una respuesta del servidor', httpStatus: 500 })
        } else {
          console.error('Error', error.message);
          return reject({ message: error.message, httpStatus: 500 })
        }
      }
    })
  },

  getExternal: (url: string, params: Object, options: optionsInterface) => {

    return new Promise(async (resolve, reject) => {
      try {
        let headersToSend = Object.assign(
          {
            'Accept-Language': i18next.language || window.localStorage.getItem('lang') || 'es',
          }, options.headers || {});

        const config: any = {
          url: url,
          data: params
        }
        config.headers = headersToSend
        let res = await axios.get(url, config)
        //console.log('Axios Response:', res.data);
        return resolve(res.data)
      } catch (error: any) {
        console.log(error.response);
        let errorMessage = options.message || (i18next.language == 'en' ? 'At the moment we are unable to process the information' : 'Por el momento no podemos procesar la información');
        console.error('URL: ', url)
        if (error.response) {
          console.error(error.response.data);
          return reject({ message: error.response.data && error.response.data.message || errorMessage, httpStatus: error.response.status })
        } else if (error.request) {
          console.error({ message: error.request, httpStatus: 500 });
          return reject({ message: i18next.language == 'en' ? 'No response was received from the server' : 'No se recibió una respuesta del servidor', httpStatus: 500 })
        } else {
          console.error('Error', error.message);
          return reject({ message: error.message, httpStatus: 500 })
        }
      }
    })
  },

  handleError(httpStatus: any, message: string) {
    let errorMsg = ''
    switch (httpStatus) {
      case 401:
        errorMsg = i18next.language == 'en' ? 'Your session has ended and you will be redirected to Login.' : 'Su sesión ha terminado sera redireccionado al Login.'
        //localStorage.removeItem('token');
        //localStorage.removeItem('customer');
        // go to login
        location.href = '/logout'
        break;
      case 403:
        errorMsg = i18next.language == 'en' ? 'Your session has ended and you will be redirected to Login.' : 'Su sesión ha terminado sera redireccionado al Login.'
        //localStorage.removeItem('token');
        //localStorage.removeItem('customer');
        // go to login
        location.href = '/logout'
        break;
      default:
        errorMsg = message || 'Error'
    }
    return errorMsg
  }
}

export default Request;