import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import Request from "src/Tools/Request";
import Config from "src/Configuration";
import i18n from 'src/utils/i18n';
const API_URL = `${Config.backendUrl}/auth`;

interface StateType {
  userData: any,
  isLoggedIn: boolean,
  addId: object
}

const userData: any = JSON.parse(localStorage.getItem("userData") || "0");
console.log("userData from localStorage", userData)
const initialState = {
  isLoggedIn: userData.token && true || false,
  userData: userData || {},
  addId: {}
};
export const UserDataSlice = createSlice({
  name: 'UserDataPost',
  initialState,
  reducers: {
    doLogin: (state: any, action: any) => {
      state.userData = action.payload;
    },
    isLogged: (state: any, action: any) => {
      state.isLoggedIn = action.payload;
    },
    doLogout: (state: any) => {
      state.isLoggedIn = false
      state.userData = {}
    },
    doLoginByCompleteUser: (state: any, action: any) => {
      state.userData = action.payload;
    },
    doSetAddId: (state: any, action: any) => {
      state.addId = action.payload;
    },
    doSetAdditionalInformation: (state: any, action: any) => {
      state.addId = action.payload;
    }
  },
});

export const { doLogin, isLogged, doLogout, doLoginByCompleteUser, doSetAddId } = UserDataSlice.actions;

export const login =
  (username: string, password: string, token : null) => async (dispatch: AppDispatch) => {
    let params = {
      username, password, token
    }
    try {
      const response: any = await Request.post(`${API_URL}/login`, params, {})
      localStorage.setItem("userData", JSON.stringify(response))
      console.log(i18n.language, response)
      if (response && response.captchaRequired) {
        return response
      } else if (response && response.incompleteUser) {
        localStorage.setItem("incomplete", JSON.stringify(response))
        return response
      } else {
        if (i18n.language != response.customer.language) {
          i18n.changeLanguage(response.customer.language)
        }
        localStorage.setItem("language", response.customer.language)
        dispatch(doLogin(response));
        dispatch(isLogged(true));
        return response
      }
    } catch (error: any) {
      throw new Error(error.message || false);
    }
  };

export const updateToken = (customerInfo: any) => async (dispatch: AppDispatch) => {
  localStorage.setItem("userData", JSON.stringify(customerInfo))
  localStorage.setItem("language", customerInfo['customer'].language)
  dispatch(doLogin(customerInfo));
};

export const logout = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("userData")
  dispatch(doLogout());
};

export const loginByCompleteUser =
  (response: any) => async (dispatch: AppDispatch) => {
    localStorage.setItem("userData", JSON.stringify(response))
    console.log(i18n.language, response)
    if (i18n.language != response.customer.language) {
      i18n.changeLanguage(response.customer.language)
    }
    localStorage.setItem("language", response.customer.language)
    dispatch(doLogin(response));
    dispatch(isLogged(true));
  };

export const setAddId =
  (ids: any) => async (dispatch: AppDispatch) => {
    dispatch(doSetAddId(ids));
  };

export const setAdditionalInformation =
  (additionalInformation: any) => async (dispatch: AppDispatch) => {
    dispatch(doSetAddId(additionalInformation));
  };

export default UserDataSlice.reducer;
