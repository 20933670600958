import Configuration from "src/Configuration";
import Request from "./Request"
const { v4: uuidv4 } = require('uuid');

let DeviceInfo = {
  getDeviceInfo() {
    let uniqueId = localStorage.getItem('uniqueId') || uuidv4();
    localStorage.setItem('uniqueId', uniqueId)
    return {
      uniqueId: uniqueId,
      navigator: getNavigator(),
      userAgent: window.navigator && window.navigator.userAgent || '',
      platform: window.navigator && window.navigator.platform || '',
    }
  },

  saveDeviceInfoOnServer: async (deviceInfo: object) => {
    try {
      return await Request.post(`${Configuration.backendUrl}/device/saveDeviceInfo`, deviceInfo, {});
    } catch (e) {
      console.error('Error: ', e);
      return false;
    }
  },
}

let getNavigator = () => {
  let sUsrAg = window.navigator.userAgent;

  if (sUsrAg.indexOf("Chrome") > -1) {
    return "Google Chrome";
  } else if (sUsrAg.indexOf("Safari") > -1) {
    return "Apple Safari";
  } else if (sUsrAg.indexOf("Opera") > -1) {
    return "Opera";
  } else if (sUsrAg.indexOf("Firefox") > -1) {
    return "Mozilla Firefox";
  } else if (sUsrAg.indexOf("MSIE") > -1) {
    return "Microsoft Internet Explorer";
  }
  return ''
}

export default DeviceInfo