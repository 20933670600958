import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import AppConfiguration from 'src/Tools/AppConfiguration';
import en from "./Locales/en.json";
import es from './Locales/es.json';

const defaultLang = localStorage.getItem('language') || 'es';

const resources = {
  en: {
    translation: { ...en }
  },
  es: {
    translation: { ...es }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: defaultLang,
    debug: true,
    compatibilityJSON: 'v3',
    interpolation: {
      escapeValue: false,
    }
  });

AppConfiguration.getI18n().then(async (res: any) => {
  i18n.addResourceBundle('en', 'translation', res.en)
  i18n.addResourceBundle('es', 'translation', res.es)
  i18n.changeLanguage(defaultLang)
})

export default i18n;
