import { t } from 'i18next'
import Configuration from 'src/Configuration'
import Swal from 'sweetalert2'

let error = (title : string, text : string ) => {
  Swal.fire({
    title, 
    icon: 'error', 
    text : text,
    confirmButtonColor : Configuration.primaryColor,
    confirmButtonText : `${t('generic.accept')}`,
    cancelButtonColor: Configuration.secondaryColor,
  })
}

let success = (title : string, text : string) => {
  Swal.fire({
    title, 
    icon : 'success',
    text : text,
    confirmButtonColor : Configuration.primaryColor,
    confirmButtonText : `${t('generic.accept')}`,
    cancelButtonColor: Configuration.secondaryColor,
  })
}

let info = (title : string, text : string) => {
  Swal.fire({
    title,
    icon : 'info',
    text : text,
    confirmButtonColor : Configuration.primaryColor,
    confirmButtonText : `${t('generic.accept')}`,
    cancelButtonColor: Configuration.secondaryColor,
  })
}

let question = (title : string, text : string, success : any) => {
  Swal.fire({
    title,
    icon : 'question',
    text : text,
    showCancelButton: true,
    cancelButtonText: `${t('generic.cancel')}`,
    cancelButtonColor: Configuration.secondaryColor,
    confirmButtonColor : Configuration.primaryColor,
    confirmButtonText : `${t('generic.accept')}`,
  }).then(success)
}

let custom = (options : any) => {
  return Swal.fire(options)
}

let close = () => {
  try {
    Swal.close();
  } catch (e) { }
}

export default { error, success, info, question, custom, close }