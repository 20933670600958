import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    active: false
};

const TransactionTrackSlice = createSlice({
    name: 'transactionTrack',
    initialState,
    reducers: {
        setActiveTransTrack: (state, action) => {
            state.active = action.payload;
        },
    },
});

export const { setActiveTransTrack } = TransactionTrackSlice.actions;
export default TransactionTrackSlice.reducer;
